<template>
    <div>
      <national-trademark-list></national-trademark-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  
  